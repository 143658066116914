import { graphql, useStaticQuery } from "gatsby";
import { PropsWithChildren, ReactNode } from "react";

import ArticleHeader from "@/components/layout/ArticleLayout/ArticleHeader";
import Footer from "@/components/layout/Footer";
import Seo from "@/components/layout/Seo";

import * as styles from "./styles.module.scss";

interface Props {
  children: ReactNode | ReactNode[];
  seo: StoryblokMetaData;
  type: TemplateType;
  documentUrl?: string;
  deadlineDate?: string;
  eventLink?: string;
  isFinished?: boolean;
  isSummary?: boolean;
  agenda?: string;
  title: string;
}

export function ArticleLayout({
  children,
  seo,
  type,
  documentUrl,
  title,
  isFinished,
  deadlineDate,
  isSummary,
  agenda,
  eventLink,
}: PropsWithChildren<Props>): JSX.Element {
  const data = useStaticQuery<{
    header: StoryblokStory;
    footer: StoryblokStory;
  }>(graphql`
    query {
      header: storyblokEntry(field_component: { eq: "template_header" }) {
        content
      }
      footer: storyblokEntry(field_component: { eq: "template_footer" }) {
        content
      }
    }
  `);
  const { footer, header } = data;

  const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
  const parsedFooter = JSON.parse(footer.content) as FooterTemplate;

  return (
    <>
      <Seo data={seo} ogType={type} />
      <ArticleHeader
        {...parsedHeader}
        documentUrl={documentUrl}
        title={title}
        isFinished={isFinished}
        deadlineDate={deadlineDate}
        isSummary={isSummary}
        agenda={agenda}
        eventLink={eventLink}
      />

      <main className={styles.wrapper}>{children}</main>
      <Footer {...parsedFooter} />
    </>
  );
}
